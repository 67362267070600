import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from '../../common/withRouter'
import { APIGetTemplates } from '../../apis/system'

class Promotions extends Component {

    constructor() {
        super()
        this.state = {
            list: []
        }
    }

    componentDidMount() {
        this.props.APIGetTemplates('PROMOTION_SECTION', (isSuccess, data, errorMessage) => {
            if (isSuccess) {
                let list = data.map((item) => {
                    return JSON.parse(item.content)
                })
                list = list.sort(() => 0.5 - Math.random()).splice(0,3)
                this.setState({ list })
            }
        })
    }

    render() {
        const urlPrefix = 'https://mcjtools-prod-assets.s3.amazonaws.com/images'

        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        return (
            <main id="spotlights" className="text-center">
                <div className="container">
                    <div className='section-title'>
                        <h2>Promotions</h2>
                    </div>
                </div>
                <div className="container d-flex justify-content-between mt-5">
                    <div className="container overflow-visible">
                        <div className="row">
                            {
                                this.state.list.map((item, i) => {
                                    return (
                                        <div key={`${i}.${item.title}`}  className="col-lg-4 col-md-6 col-sm-6" style={{ marginTop: '24px'}}>
                                            <div className="card prod-card h-100 pb-3 shadow"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => window.location.href = item.url }>
                                                <img src={urlPrefix+item.image} className="img-fluid" alt={item.title} />
                                                <div className="card-body">
                                                    <h4 className="card-title">{item.title}</h4>
                                                    <p className="card-text">
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}


const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    APIGetTemplates
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Promotions))
