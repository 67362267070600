import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Carousel } from 'react-bootstrap';
import "./carousel.css";
import { SetCategoryAction } from '../../apis/products';
import { withRouter } from '../../common/withRouter'

class LandingCarousel extends Component {

  render() {
    var region = this.props.router.params.region || ""
    if (region.length > 0) {
        region = `/${region}`
    }
    return (
      <Carousel>
        <Carousel.Item className='carousel-item' onClick={() => window.open('/products?category=5', '_blank')}>
          <img className="d-block w-100 carousel-item-bg-img" src="/images/carousels/carousel_1.jpg" alt="Shot Show" />
        </Carousel.Item>
        <Carousel.Item className='carousel-item' onClick={() => window.open('/store', '_blank')}>
          <img className="d-block w-100 carousel-item-bg-img" src="/images/carousels/carousel_2.jpg" alt="Promotion" />
        </Carousel.Item>
      </Carousel>
    );
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      SetCategoryAction
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingCarousel))
