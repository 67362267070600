import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { APISignIn } from '../../apis/auth'
import '../../common/common.css'
import './signin.css'
import { withRouter } from '../../common/withRouter'

class SignIn extends Component {

  constructor() {
      super()
      this.state = {
        email:"",
        password:""
      }
  }

  setErrMsg = (errMsg) => {
    this.setState({
      errMsg
    })
  }

  onValueChange = e => {
    this.setState({
      [e.target.name]:e.target.value,
      errMsg:""
    })
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.authState.index !== nextState.index) {
        this.setState({
          errMsg:nextProps.authState.error,
          index:nextProps.authState.index
        })
    }
    return true
  }

  login = () => {
    this.setErrMsg("")
    let loginObj = {
      email: this.state.email,
      password: this.state.password
    }
    this.props.APISignIn(loginObj)
  }
    
  render() {
    var region = this.props.router.params.region || ""
    if (region.length > 0) {
        region = `/${region}`
    }
    const {authed} = this.props.authState
    if (authed) {
      return <Navigate replace to={`${region}/`} />
    }
    return (
      <div className='root'>
        <div className='top'>
          <Form className='signinBox' onSubmit={this.login}>
            <h3>Sign In</h3>
            <p className='tipLabel'>
              E-mail address
            </p>
            <Form.Group className='mb-3 inputer' controlId='formBasicEmail'>
              <Form.Control name='email' type='email' placeholder='Email' value={this.state.email} onChange={this.onValueChange}/>
            </Form.Group>
            <p className='tipLabel'>
              Password
            </p>
            <Form.Group className='mb-3 inputer' controlId='formBasicPassword'>
              <Form.Control name='password' type='password' placeholder='Password' value={this.state.password} onChange={this.onValueChange} />
            </Form.Group>
            <div className='errMsg'>{this.state.errMsg}</div>
            <Button variant='warning' style={{ width: '100%' }} onClick={this.login}>
              Sign In
            </Button>
            <Link to={`${region}/forgotpassword`} style={{ marginTop: '16px', fontSize: '12px', textDecoration: 'none' }}>Forgot Password?</Link>
          </Form>
          <span className='signUpTipDivider'>New to McJTOOLS?</span>
          <div style={{ marginBottom: '16px', width: '100%', maxWidth: '400px' }}>
            <Link to={`${region}/signup`} className='d-flex justify-content-center' style={{ textDecoration: 'none' }}>
              <Button variant='secondary' style={{ width: '100%' }}>
                Create your McJTOOLS account
              </Button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authState:state.authState
})

const mapDispatchToProps = dispatch =>{
  return bindActionCreators(
    {
      APISignIn
    },
    dispatch
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))

