import React, { Component } from 'react'
import { Badge, Button, Spinner, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    APIRemoveFromCart,
    APIGetCart,
    APIAddToCart,
    APICreateOrder,
    APIGetOrders,
    APIConfirmOrder
} from '../../../apis/cart'
import {
    APIGetUserInfo,
    APIGetAddresses
} from '../../../apis/user'
import {
    APIGetProductDetail
} from '../../../apis/products'
import {
    APIGetCustomerPromotionInfo
} from '../../../apis/promotion'
import {
    APIGuestGetOrder,
    APIGuestCreateOrder,
    APIGuestCompleteOrder
} from '../../../apis/guest'
import "./cart.css"
import MCJ from '../../../common/def'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import OrderPayment from '../order/orderPay'
import { GetMonthName } from '../../../common/utils'
import { withRouter } from '../../../common/withRouter'
import OrderCard from '../user/orderCard';
import { addToGuestCart, clearGuestCart, getGuestAddresses, getGuestCart, getPendingOrder, removeFromGuestCart, setPendingOrder } from '../../../common/guestCart';
import CartAddressesModal from './cartAddressesModal'
import Signup from '../../auth/signup'

class Cart extends Component {

    constructor() {
        super()
        this.state = {
            localCart: getGuestCart(),
            isLoading: true,
            isChangingShipping: false,
            isChangingBilling: false,
            currentCoupon: undefined,
            currentPromotion: undefined,
            errorMessage: undefined,
            isPlacingOrder: false,
            newPlacedOrder: undefined,
            orderInfo: {
                code: null,
                shipping: {
                    name: "",
                    phone: "",
                    email: "",
                    address: "",
                    city: "",
                    state: "",
                    postal: "",
                    country: ""
                },
                billing: null
            },
            cartQuantities: {},
            requestedProductIDs: [],
        }
    }

    async componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        if (this.props.authState.authed) {
            // Get orders
            await this.loadAllOrders()
            // Get user info
            await this.props.APIGetUserInfo()
            await this.props.APIGetAddresses()
            const userInfo = this.props.userState?.userInfo || {}
            this.setState({
                orderInfo: {
                    code: null,
                    shipping: {
                        name: userInfo.fullName || "",
                        phone: userInfo.phone || "",
                        email: userInfo.email || "",
                        address: userInfo.address || "",
                        city: userInfo.city || "",
                        state: userInfo.state || "",
                        postal: userInfo.postal || "",
                        country: userInfo.country || ""
                    },
                    billing: null
                }
            })
            if (Object.keys(this.props.prodState.allProductsById).length > 0) {
                this.markLoadingDone()
            }
        } else {
            this.setState({ 
                cart: getGuestCart(),
                newPlacedOrder: getPendingOrder(),
                orderInfo: {
                    code: null,
                    shipping: getGuestAddresses()[0] || {},
                    billing: getGuestAddresses()[0] || {}
                }
            })
            this.loadProductDetails()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // When user is logged in, load product details
        const shouldLoadProductDetails = this.props.authState.authed && prevProps.cartState.cart.length === 0 && this.props.cartState.cart.length > 0
        if (shouldLoadProductDetails) {
            this.loadProductDetails()
        }
        // When all products are loaded, mark loading done
        const shouldMarkLoadingDone = Object.keys(prevProps.prodState.allProductsById).length === 0 && Object.keys(this.props.prodState.allProductsById).length > 0
        if (shouldMarkLoadingDone) {
            this.markLoadingDone()
        }
        // When session is restored, load data
        const shouldRestoreSession = !prevProps.authState.authed && this.props.authState.authed
        if (shouldRestoreSession) {
            this.loadData()
        }
    }

    loadProductDetails = async () => {
        let cart = this.props.cartState?.cart || []
        if (!this.props.authState.authed) {
            cart = this.state.localCart
        }
        for (const item of cart) {
            const productId = item.productId
            if (!this.state.requestedProductIDs.includes(productId)) {
                await this.props.APIGetProductDetail(this.props.authState.authed, productId)
                this.setState(preState => {
                    return {
                        ...preState,
                        requestedProductIDs: [ ...preState.requestedProductIDs, productId ]
                    }
                })
            }
        };
        this.markLoadingDone()
    }

    markLoadingDone = () => {
        if (Object.keys(this.props.prodState.allProductsById).length > 0) {
            this.setState({ isLoading: false })
        }
    }

    handleModalClose = () => {
        this.setState({
            isChangingShipping: false,
            isChangingBilling: false
        })
    }

    loadAllOrders = async () => {
        if (this.props.authState.authed) {
            await this.props.APIGetOrders()
            const pendingOrder = this.getPendingOrder()
            if (pendingOrder !== undefined && pendingOrder !== null) {
                this.setState({ newPlacedOrder: pendingOrder })
            }
        }
    }

    getPendingOrder = () => {
        const orderList = this.props.cartState.orderList || []
        const cartStatus = this.props.userState.userInfo?.cartStatus || ''
        let pendingOrder = null
        if (cartStatus === 'PROCESSING') {
            const createdOrders = orderList.filter(order => order.status === 'CREATED')
            if (createdOrders.length > 0) {
                pendingOrder = createdOrders[0]
            }
        }
        return pendingOrder
    }

    handleAddressSelect = async (address) => {
        let name = address.name || address.fullName
        if (typeof name === 'undefined' || name === null || name.length === 0) {
            name = this.props.userState.userInfo.fullName
        }
        let phone = address.phone
        if (typeof phone === 'undefined' || phone === null || phone.length === 0) {
            phone = this.props.userState.userInfo.phone
        }
        let email = address.email
        if (typeof email === 'undefined' || email === null || email.length === 0) {
            email = this.props.userState.userInfo.email
        }
        await this.setState(preState => {
            if (preState.isChangingShipping) {
                return {
                    ...preState,
                    isChangingShipping: false,
                    isChangingBilling: false,
                    orderInfo: {
                        ...preState.orderInfo,
                        shipping: {
                            name,
                            phone,
                            email,
                            address: address.address || "",
                            city: address.city || "",
                            state: address.state || "",
                            postal: address.postal || "",
                            country: address.country || ""
                        }
                    }
                }
            } else if (preState.isChangingBilling) {
                return {
                    ...preState,
                    isChangingShipping: false,
                    isChangingBilling: false,
                    orderInfo: {
                        ...preState.orderInfo,
                        billing: {
                            name,
                            phone,
                            email,
                            address: address.address || "",
                            city: address.city || "",
                            state: address.state || "",
                            postal: address.postal || "",
                            country: address.country || ""
                        }
                    }
                }
            }
        })
    }

    renderAddressesModal = () => {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        return (
            <CartAddressesModal
                showDialog={this.state.isChangingShipping || this.state.isChangingBilling}
                onHide={this.handleModalClose}
                dialogTitle={this.state.isChangingShipping ? "Pick Shipping Address" : "Pick Billing Address"}
                userInfo={this.props.userState?.userInfo}
                onAddressSelect={this.handleAddressSelect}
            />
        )
    }

    renderEmptyMessage = () => {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const isGuestOrderPlaced = (this.state.newPlacedOrder && this.state.newPlacedOrder.isGuest)
        return (
            <div>
                <h3 style={{ textAlign: 'center', marginTop: '80px', marginBottom: '80px' }}>
                    { this.state.newPlacedOrder && "Thank you for your order!" }
                    { !this.state.newPlacedOrder && "Your shopping cart is empty." }
                </h3> 
                { this.state.newPlacedOrder && <OrderCard
                    order={this.state.newPlacedOrder}
                    isGuest={!this.props.authState.authed}
                />}
                <hr/> 
                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '16px' }}>
                    { !isGuestOrderPlaced && (
                        <Link to={`${region}/store`}>
                            <Button variant="warning">Continue Shopping</Button>
                        </Link>  
                    )}
                    { isGuestOrderPlaced && (
                        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <h4>Create an Account</h4>
                            <span>Save your order history and make future purchases easier</span>
                            <Signup embedded={true} email={this.state.orderInfo.billing?.email || this.state.orderInfo.shipping?.email} />
                        </div>
                    )}
                </div>
            </div>
        )
    }

    updateItem = async (id) => {
        const { allProductsById } = this.props.prodState
        let product = allProductsById[id]
        let newQuantity = this.state.cartQuantities[id]
        newQuantity = parseInt(newQuantity)
        if (isNaN(newQuantity) || newQuantity <= 0) {
            return
        }
        await this.setState(preState => {
            return {
                ...preState,
                cartQuantities: {
                    ...preState.cartQuantities,
                    [id]: undefined
                }
            }
        })
        if (this.props.authState.authed) {
            await this.props.APIAddToCart(product, newQuantity)
        } else {
            addToGuestCart(product, newQuantity, true)
            this.setState({ localCart: getGuestCart() })
        }
    }

    removeItem = (id) => {
        if (this.props.authState.authed) {
            this.props.APIRemoveFromCart(id)
        } else {
            removeFromGuestCart(id)
            this.setState({ localCart: getGuestCart() })
        }
    }

    placeOrder = async (gateway) => {
        if (this.state.orderInfo.billing === null) {
            await this.setState(preState => {
                return {
                    ...preState,
                    orderInfo: {
                        ...preState.orderInfo,
                        gateway,
                        billing: preState.orderInfo.shipping
                    }
                }
            })
        } else {
            await this.setState(preState => {
                return {
                    ...preState,
                    orderInfo: {
                        ...preState.orderInfo,
                        gateway
                    }
                }
            })
        }
        await this.setState({ isPlacingOrder: true })
        if (this.props.authState.authed) {
            this.props.APICreateOrder(this.state.orderInfo, (success, json, error) => {
                this.setState({ isPlacingOrder: false, errorMessage: error })
                this.props.APIGetUserInfo()
                this.loadAllOrders()
            })
        } else {
            let orderInfo = {
                ...this.state.orderInfo,
                cart: this.state.localCart
            }
            this.props.APIGuestCreateOrder(orderInfo, (success, data, error) => {
                this.setState({ 
                    isPlacingOrder: false,
                    newPlacedOrder: {
                        ...data,
                        isGuest: true
                    },
                    errorMessage: error
                })
                setPendingOrder(data)
            })
        }
    }

    handleQuantityChanges = e=>{
        const productId = e.target.name
        const newQuantity = e.target.value
        this.setState(preState => {
            return {
                ...preState,
                cartQuantities: {
                    ...preState.cartQuantities,
                    [productId]: newQuantity
                }
            }
        })
    }
    
    handleCouponChanges = e=>{
        let currentCoupon = e.target.value
        currentCoupon = currentCoupon.length === 0 ? null : currentCoupon
        this.setState({ currentCoupon: currentCoupon?.toUpperCase() })
    }

    applyCoupon = () => {
        const currentCoupon = this.state.currentCoupon
        if (currentCoupon === null || currentCoupon.length === 0) {
            this.setState(preState => {
                return {
                    ...preState,
                    currentCoupon: undefined,
                    currentPromotion: undefined,
                    orderInfo: {
                        ...preState.orderInfo,
                        code: undefined
                    }
                }
            })
        } else {
            this.props.APIGetCustomerPromotionInfo(currentCoupon, (success, data, error) => {
                if (success) {
                    this.setState(preState => {
                        return {
                            ...preState,
                            currentPromotion: data,
                            errorMessage: undefined,
                            orderInfo: {
                                ...preState.orderInfo,
                                code: currentCoupon
                            }
                        }
                    })
                } else {
                    this.setState(preState => {
                        return {
                            ...preState,
                            currentPromotion: data,
                            errorMessage: error,
                            orderInfo: {
                                ...preState.orderInfo,
                                code: undefined
                            }
                        }
                    })
                }
            })
        }
    }

    getItemQuantity = (item) => {
        let quantity = item.quantity
        let modifiedQuantity = this.state.cartQuantities[item.productId]
        if (typeof modifiedQuantity !== 'undefined' && modifiedQuantity !== null) {
            modifiedQuantity = parseInt(modifiedQuantity)
            if (isNaN(modifiedQuantity)) {
                modifiedQuantity = 0
            }
            quantity = modifiedQuantity
        }
        return quantity
    }

    getPriceByQuantity = (quantity, productId) => {
        const { productDetailsById } = this.props.prodState
        const productDetail = productDetailsById[productId]
        const { userInfo } = this.props.userState
        const isDealer = typeof userInfo !== 'undefined' && userInfo !== null && userInfo.dealer === true
        if (typeof productDetail === 'undefined' || productDetail === null) {
            return null
        }
        let prices = productDetail.prices
        if (typeof prices === 'undefined' || prices === null || prices.length === 0) {
            return null
        }
        prices = prices.slice().sort((a, b) => (a.min > b.min) ? 1 : -1)
        let priceRange = undefined
        for (let i = 0; i < prices.length; i++) {
            priceRange = prices[i]
            if (isDealer && priceRange.dealer === true) {
                break
            } else if (quantity >= priceRange.min && quantity <= priceRange.max) {
                break
            }
        }
        if (priceRange !== undefined) {
            return priceRange.price
        }
        return null
    }

    renderCartItem = (item, product, quantity, price, promotion) => {
        if(typeof product === 'undefined' || product === null) {
            return null
        }
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        let imageUrlPrefix = MCJ.DefaultImgPrefix            
        const {system} = this.props.userState
        if (typeof system !== 'undefined' && system !== null) {
            imageUrlPrefix = system.imageUrlPrefix
        }
        let priceStr = `$${(price * quantity).toFixed(2)}`
        let prodImg = imageUrlPrefix + product.thumbnail
        return (
            <div key={item.id}>
                <div key={item.id} className='cartProdItem'>
                    <img src={prodImg} alt={product.name} className='cartProdImg' />
                    <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', padding: '4px'}}>
                        <div style={{ display: 'flex', fontSize: '14px', flexDirection: 'row', justifyContent: 'flex-begin' }}>
                            <div style={{ flexGrow: 1 }}>
                                <Link to={`${region}/product/${product.id}`}>
                                    {product.name}
                                </Link>
                            </div>
                            <div>
                                <div style={{ marginLeft: '8px', fontWeight: 'bolder', flexBasis: 1 }}>
                                    {priceStr}
                                </div>
                                {promotion && <div style={{ textAlign: 'right', fontSize: '10px', fontWeight: 'normal', color: 'red' }}>
                                    ({promotion})
                                </div>}
                            </div>
                        </div>
                        <div style={{ marginTop: '8px', fontSize: '14px', display: 'flex' }}>
                            Qty:&nbsp;
                            <Dropdown>
                                <Dropdown.Toggle variant="warning" style={{
                                    width: '100%',
                                    fontSize: '12px',
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                    cursor: 'pointer'
                                }}>
                                    {quantity}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        [1,2,3,4,5,6,7,8,9,10].map(qty => {
                                            return (
                                                <Dropdown.Item
                                                    key={qty}
                                                    active={qty === quantity}
                                                    name={item.productId}
                                                    value={qty}
                                                    style={{ fontSize: '12px' }}
                                                    onClick={async (e) => {
                                                        e.target.value = qty
                                                        await this.handleQuantityChanges(e)
                                                        this.updateItem(item.productId)
                                                    }}>
                                                    {qty}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            <div style={{ flexGrow: 1 }}/>
                            <Button
                                style={{
                                    backgroundColor: 'transparent',
                                    borderColor: 'transparent',
                                    color: 'red',
                                    marginLeft: '8px',
                                    height: '24px',
                                    fontSize: '12px',
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                    cursor: 'pointer'
                                }}
                                onClick={()=>{
                                this.removeItem(item.productId)
                            }}>
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            <hr/>
        </div>
        )
    }

    renderCart = (cart)=> {
        const {allProductsById} = this.props.prodState
        if (typeof allProductsById === 'undefined' || allProductsById === null) {
            return null
        }
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        let itemCount = 0
        let totalPrice = 0
        let items = cart.map(item => {
            const product = allProductsById[item.productId]
            if (typeof product === 'undefined' || product === null) {
                return null
            }
            const quantity = this.getItemQuantity(item)
            let price = parseFloat(product.price)
            const priceByQuantity = parseFloat(this.getPriceByQuantity(quantity, item.productId))
            if (priceByQuantity !== null && !isNaN(priceByQuantity)) {
                price = priceByQuantity
            }
            const { percentage, amount, products: productIds } = this.state.currentPromotion || {}
            let promotion = undefined
            if (percentage !== undefined && amount !== undefined && productIds !== undefined) {
                if (productIds.includes(item.productId)) {
                    if (percentage !== 0) {
                        price *= (1-percentage/100.0)
                        promotion = `-${percentage}%`
                    } else if (amount !== 0) {
                        price -= amount/100.0
                        promotion = `-$${amount/100.0}`
                    }
                }
            }
            totalPrice += (price * quantity)
            itemCount += quantity
            return this.renderCartItem(item, product, quantity, price, promotion)
        })
        totalPrice = `${totalPrice.toFixed(2)}`

        // ship information
        let isShippingValid = true
        const orderInfo = this.state.orderInfo
        const shipping = orderInfo.shipping
        if (orderInfo.name === "" || shipping.address === "" || shipping.city === "" || shipping.state === "" || shipping.postal === "") {
            isShippingValid = false
        }
        const isShippingEmpty = Object.values(shipping).every(value => value === "")
        let billing = orderInfo.billing
        if (typeof billing === 'undefined' || billing === null || isShippingEmpty) {
            billing = shipping
        }
        const isBillingEmpty = Object.values(billing).every(value => value === "")
        const shipInfo = (
            <div className='cartShipping'>
                <div style={{ fontSize: '12px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                        <span style={{ fontWeight: '700' }}>
                            Shipping Address
                        </span>
                        <Link
                            style={{ marginLeft: '4px', fontSize: '11px', textDecoration: 'none' }}
                            to={'#'}
                            onClick={ () => {
                                this.setState({
                                    isChangingShipping:true
                                })
                            }}>
                            Change
                        </Link>
                    </div>
                    { !isShippingEmpty && (
                        <div>
                            <div>{(shipping.name || "").toUpperCase()}</div>
                            <div>{(shipping.address || "").toUpperCase()}</div>
                            <div>{`${(shipping.city || "").toUpperCase()}`}, {`${(shipping.state || "").toUpperCase()} ${shipping.postal.toUpperCase()}`}</div>
                            <div>{(shipping.country || "").toUpperCase()}</div>
                            <div>{(shipping.phone || "").toUpperCase()}</div>
                            <div>{(shipping.email || "").toUpperCase()}</div>
                        </div>
                    )}
                    { isShippingEmpty && (
                        <div style={{ color: 'grey' }}>
                            No address selected.
                        </div>
                    )}
                </div>
                <div style={{ marginLeft: '64px', fontSize: '12px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                        <span style={{ fontWeight: '700' }}>
                            Billing Address
                        </span>
                        <Link
                            style={{ marginLeft: '4px', fontSize: '11px', textDecoration: 'none' }}
                            to={'#'}
                            onClick={ () => {
                                this.setState({
                                    isChangingBilling:true
                                })
                            }}>
                            Change
                        </Link>
                    </div>
                    <div>
                        { !isBillingEmpty && (
                            <>
                                <div>{(billing.name || "").toUpperCase()}</div>
                                <div>{(billing.address || "").toUpperCase()}</div>
                                <div>{`${(billing.city || "").toUpperCase()}`}, {`${(billing.state || "").toUpperCase()} ${billing.postal.toUpperCase()}`}</div>
                                <div>{(billing.country || "").toUpperCase()}</div>
                                <div>{(billing.phone || "").toUpperCase()}</div>
                                <div>{(billing.email || "").toUpperCase()}</div>
                            </>
                        )}
                        { isBillingEmpty && (
                            <div style={{ color: 'grey' }}>
                                No address selected.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )

        return (
            <div>
                {shipInfo}
                <h3>Your Items</h3>
                <hr/>
                {items}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{`Subtotal (${itemCount} items): $${totalPrice}`}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <span style={{ marginRight: '8px' }}>
                        Coupon:
                    </span>
                    { (this.state.currentCoupon === undefined || (this.state.currentCoupon !== this.state.orderInfo.code)) && (
                        <input
                            style={{ width: '200px', height: '24px', textTransform: 'uppercase' }}
                            name='coupon'
                            onChange={this.handleCouponChanges}
                            defaultValue={this.state.orderInfo.code || ""} />
                    )}
                    { (this.state.currentCoupon === undefined || (this.state.currentCoupon !== this.state.orderInfo.code)) && (
                        <Button
                            variant='warning'
                            className='CommonButton shadow-none'
                            style={{
                                marginLeft: '8px',
                                height: '24px',
                                fontSize: '14px',
                            }}
                            onClick={ this.applyCoupon }>
                            Apply
                        </Button>
                    )}
                    { this.state.currentCoupon !== undefined && this.state.currentCoupon === this.state.orderInfo.code && (
                        <span>
                            { this.state.currentCoupon }
                        </span>
                    )}
                    { this.state.currentCoupon !== undefined && this.state.currentCoupon === this.state.orderInfo.code && (
                        <Button
                            variant='danger'
                            className='CommonButton shadow-none'
                            style={{
                                marginLeft: '8px',
                                height: '24px',
                                fontSize: '14px',
                            }}
                            onClick={ () => {
                                this.setState(preState => {
                                    return {
                                        ...preState,
                                        currentCoupon: undefined,
                                        currentPromotion: undefined,
                                        errorMessage: undefined,
                                        orderInfo: {
                                            ...preState.orderInfo,
                                            code: undefined
                                        }
                                    }
                                })
                            } }>
                            X
                        </Button>
                    )}
                </div>
                <div>
                    <span
                        className='CommonErrorMessage'
                        style={{ textAlign: 'right', marginTop: '8px' }}>
                        {this.state.errorMessage}
                    </span>
                </div>
                <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
                    {this.state.isPlacingOrder === true && <Spinner className='forgotSpinner' animation='border' style={{ marginTop: '4px', marginRight: '16px', alignSelf: 'flex-start' }} />}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'right', gap: '8px' }}>
                        <Button variant='warning' disabled={!isShippingValid} onClick={ () => { this.placeOrder('Stripe') }}>Place Order</Button>
                    </div>
                </div>
                <div style={{ marginTop: '16px', marginBottom: '16px', display: 'flex', justifyContent: 'flex-end'}}>
                    {(orderInfo.name === "") && (<div style={{ fontSize: '13px', color: 'red' }}>
                        Name is missing, please fill your full name in 
                        <Link style={{ marginLeft: '4px', marginRight: '4px' }} to={`${region}/user/account`}>Account</Link>
                        settings.</div>)}
                </div>
                {this.renderAddressesModal()}
            </div>
        )
    }

    renderOrderProducts = (order) => {
        if (typeof order.items === 'undefined' || order.items === null) {
            return null
        }
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const {allProductsById} = this.props.prodState
        let productList = order.items.map(prod=>{
            // Get Product Info
            let theProd = allProductsById[prod.productId]
            if (typeof theProd === 'undefined' || theProd === null) {
                return null
            }
            let prdImg = MCJ.DefaultImgPrefix + theProd.thumbnail
            let prdPrice = `$${theProd.price}`
            let prdPriceByQuantity = this.getPriceByQuantity(prod.quantity, prod.productId)
            if (prdPriceByQuantity !== null) {
                prdPrice = prdPriceByQuantity
            }
            let prdQuantity = `Quantity:${prod.quantity}`
            let prdLinkUrl = `${region}/product/${prod.productId}`
   
            return (
               <div style={{display: 'flex', fontSize: '14px', marginBottom: '8px'}} key={prod.productId}>
                   <Link to={prdLinkUrl}>
                       <img className='odImg' src={prdImg} alt={theProd.name}/>
                   </Link>
                   <div className='odProdInfo'>
                       <Link to={prdLinkUrl}>
                           <div>{theProd.name}</div>
                       </Link>
                       <div>${prdPrice}</div>
                       <div>{prdQuantity}</div>
                   </div>
               </div>
           )
       })

       let cancelButton = null 
       if (order.status === 'CREATED') {
            cancelButton = (
                <div className='orderCancelContainer'>
                    <Button variant='secondary' onClick={()=>{
                        if (this.props.authState.authed) {
                            this.props.APIConfirmOrder(false)
                        } else {
                            this.props.APIGuestCompleteOrder(false, order.reference, (success, data, error) => {
                                if (success) {
                                    setPendingOrder(undefined)
                                }
                            })
                        }
                        this.setState({
                            currentCoupon: undefined,
                            currentPromotion: undefined,
                            newPlacedOrder: undefined
                        })
                    }}>{(order.credential !== "") ? "Cancel Order" : "Reset Order"}</Button>
                </div>
            )
       }

        return (
            <div className='orderProducts'>
                {productList}
                {cancelButton}
            </div>
        )
    }

    renderOrderSummary = (order) => {
        const userInfo = this.props.userState.userInfo
        const shipping = order.shipping
        const billing = order.billing
        if (typeof order === 'undefined' || order === null) {
            return null
        }
        let feesView = null
        const fees = order.fees
        if (typeof fees !== 'undefined' && fees !== null) {
            feesView = fees.map((fee, i) => {
                return (
                    <tr key={fee.name}>
                        <td>{fee.name}: </td>
                        <td style={{ textAlign: 'right' }}>{`US$${fee.fee}`}</td>
                    </tr>
                )
            })
        }
        return (
            <div className='orderSummary'>
                <div style={{ fontSize: '12px'}}>
                    <div style={{ fontWeight: '700' }}>Shipping Address</div>
                    <div>{(shipping.name || userInfo.fullName).toUpperCase()}</div>
                    <div>{(shipping.address || "").toUpperCase()}</div>
                    <div>{`${(shipping.city || "").toUpperCase()}`}, {`${(shipping.state || "").toUpperCase()} ${shipping.postal.toUpperCase()}`}</div>
                    <div>{(shipping.country || "").toUpperCase()}</div>
                    <div>{(shipping.phone || (userInfo.phone || "")).toUpperCase()}</div>
                    <div>{(shipping.email || (userInfo.email || "")).toUpperCase()}</div>
                </div>
                <div style={{ marginLeft: '16px', fontSize: '12px' }}>
                    <div style={{ fontWeight: '700' }}>Billing Address</div>
                    <div>{(billing.name || userInfo.fullName).toUpperCase()}</div>
                    <div>{(billing.address || "").toUpperCase()}</div>
                    <div>{`${(billing.city || "").toUpperCase()}`}, {`${(billing.state || "").toUpperCase()} ${billing.postal.toUpperCase()}`}</div>
                    <div>{(billing.country || "").toUpperCase()}</div>
                    <div>{(billing.phone || (userInfo.phone || "")).toUpperCase()}</div>
                    <div>{(billing.email || (userInfo.email || "")).toUpperCase()}</div>
                </div>
                <div style={{ marginLeft: '16px', fontSize: '12px' }}>
                    <div style={{ fontWeight: '700' }}>Order Summary</div>
                    <div>
                        <table>
                            <tbody>
                                { feesView }
                                <tr>
                                    <td>Subtotal: </td>
                                    <td style={{ textAlign: 'right' }}>{`US$${order.summary?.subtotal}`}</td>
                                </tr>
                                <tr>
                                    <td>Discount: </td>
                                    <td style={{ textAlign: 'right' }}>{`-US$${order.summary?.discount}`}</td>
                                </tr>
                                <tr>
                                    <td>Tax: </td>
                                    <td style={{ textAlign: 'right' }}>{`US$${order.summary?.tax}`}</td>
                                </tr>
                                <tr>
                                    <td>Total: </td>
                                    <td style={{ textAlign: 'right' }}>{`US$${order.summary?.total}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderStripe = (order) => {
        let view = null
        if(order.status === 'CREATED') {
            if (order.stripe !== "") {
                view = (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Elements
                            stripe={loadStripe(MCJ.STRIPE_KEY)}
                            options={{
                                clientSecret: order.stripe.secret
                            }}>
                            <OrderPayment successCallback={ () => {
                                if (this.props.authState.authed) {
                                    this.setState({
                                        newPlacedOrder: {
                                            ...order,
                                            status: 'PAID'
                                        }
                                    })
                                } else {
                                    this.setState({
                                        newPlacedOrder: {
                                            ...order,
                                            status: 'PAID'
                                        },
                                        localCart: []
                                    })
                                }
                                // Get cart after 500 ms
                                setTimeout(()=>{
                                    if (this.props.authState.authed) {
                                        this.props.APIGetCart()
                                        this.loadAllOrders()
                                    } else {
                                        setPendingOrder(undefined)
                                        clearGuestCart()
                                    }
                                }, 500)
                            }}/>
                        </Elements>
                    </div>
                )
            } else {
                view = (
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center' }}>
                        <h3><Badge bg='danger'>Order Failed</Badge></h3>
                        <span style={{ width: '100%', fontSize: '13px' }}>Please double check your addresses and retry.</span>
                    </div>
                )
            }
        } else if (order.status === 'PAID') {
            view = <h3><Badge bg='success'>Paid</Badge></h3>
        }
        return view
    }

    render() {
        const newPlacedOrder = this.state.newPlacedOrder
        if(this.state.isLoading) {
            return (
                <div className='cartContainer container'>
                    <div style={{ textAlign: 'center' }}>
                        <Spinner animation='border' style={{ marginTop: '80px', marginBottom: '80px'}} />
                    </div>
                </div>
            )
        } else if (typeof newPlacedOrder !== 'undefined' && newPlacedOrder !== null && newPlacedOrder.status !== 'PAID') {
            const pendingOrder = this.state.newPlacedOrder
            let summary = this.renderOrderSummary(pendingOrder)
            let details = this.renderOrderProducts(pendingOrder)
            let orderDate = new Date(pendingOrder.createdAt)
            let strOrderDate = `${GetMonthName(orderDate.getMonth())}, ${orderDate.getDate()} ${orderDate.getFullYear()}`
            return (
                <div className='cartContainer container'>
                    <div>
                        <h2>Order Details</h2>
                    </div>
                    <div className='odContent' style={{ marginBottom: '16px' }}>
                        <div className='orderHead'>
                            <span>Ordered on {strOrderDate}</span>
                            <div>
                                <span>Order # {pendingOrder.id}</span>
                            </div>
                        </div>
                        {summary}
                        <br/>
                        {details}
                        <div className='orderStatus'>
                            { pendingOrder.gateway === 'Stripe' && this.renderStripe(pendingOrder) }
                        </div>
                    </div>
                </div>
            )
        } else {
            let cart = this.props.cartState?.cart || []
            if (!this.props.authState.authed) {
                cart = this.state.localCart
            }
            if (cart.length > 0) {
                return (
                    <div className='cartContainer container'>
                        {this.renderCart(cart)}
                    </div>
                )
            } else {
                return (
                    <div className='cartContainer container'>
                        {this.renderEmptyMessage()}
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    authState: state.authState,
    userState: state.userState,
    prodState: state.prodState,
    cartState: state.cartState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIRemoveFromCart,
        APIGetCart,
        APIAddToCart,
        APICreateOrder,
        APIGetOrders,
        APIConfirmOrder,
        APIGetUserInfo,
        APIGetAddresses,
        APIGetProductDetail,
        APIGetCustomerPromotionInfo,
        APIGuestGetOrder,
        APIGuestCreateOrder,
        APIGuestCompleteOrder
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart))