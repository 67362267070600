import { HandleResponseJson, HandleResponseError  } from './api'
import {
    getSystem,
} from "../store/userSlice"

export function APIGetSystem() {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/system')
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                return dispatch(getSystem(data))
            } else {
                return dispatch(getSystem({"imageUrlPrefix":"https://mcjtools-assets.s3.amazonaws.com/images"}))
            }
        } catch (err) {
            return dispatch(getSystem({"imageUrlPrefix":"https://mcjtools-assets.s3.amazonaws.com/images"}))
        }
    }
}

export function APIGetTemplates(type, callback) {
    return async dispatch => {
        try {
            let rsp = await fetch(`/api/system/template?type=${type}`)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const errorMessage = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, errorMessage)
        } catch (err) {
            callback(false, undefined, err)
        }
    }
}

export function APIGetBanners(callback) {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/system/banner')
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const errorMessage = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, errorMessage)
        } catch (err) {
            callback(false, undefined, err)
        }
    }
}