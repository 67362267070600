import { loadSession, signUp, signIn, signOut } from "../store/authSlice";
import { signInUser, signOutUser } from "../store/userSlice";
import { APIGetUserInfo } from "./user";

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 3600 * 24 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function ClearSession() {
  setCookie("mSessionId", "", 0);
}

export function LoadSession() {
  return (dispatch) => {
    let token = getCookie("mSessionId");
    let authed = true;
    if (token === "") {
      authed = false;
      return dispatch(
        loadSession({
          authed,
        })
      );
    } else {
      dispatch(
        loadSession({
          authed,
        })
      );
      dispatch(APIGetUserInfo());
    }
  };
}

// {
//   "email": "string", // required
//   "password": "string", // required
//   "company": "string",
//   "association": "string",
//   "fullName": "string",
//   "contactEmail": "string",
//   "phone": "string",
//   "fax": "string",
//   "address": "string",
//   "city": "string",
//   "state": "string",
//   "postal": "string",
//   "country": "string",
//   "dealer": true,
//   "ffLicense": "string",
//   "ffExpiration": "string",
//   "taxId": "string",
//   "note": "string",
//   "mobileId": "string",
//   "mobileToken": "string"
// }
export function APISignUp(payload) {
  return async (dispatch) => {
    try {
      let rsp = await fetch("/api/signup", {
        method: "post",
        body: JSON.stringify(payload),
      });

      // handle status
      const json = await rsp.json()
      const data = json.data
      if (rsp.status === 200 || rsp.status === 201) {
        dispatch(
          signUp({
            authed: true,
            error: "",
          })
        );
        dispatch(
          signInUser({
            authed: true,
            data
          })
        );
      } else {
        dispatch(
          signUp({
            authed: false,
            error: json.message ?? 'Account has beed used, please choose another account.'
          })
        );
        dispatch(
          signInUser({
            authed: false,
            data
          })
        );
      }
    } catch (err) {
      dispatch(
        signIn({
          authed: false,
          error: "Sign up failed",
        })
      );
    }
  }
}

export function APISignIn(authReq) {
  return async (dispatch) => {
    try {
      let rsp = await fetch("/api/login", {
        method: "post",
        body: JSON.stringify(authReq),
      });

      const json = await rsp.json()
      const data = json.data
      // handle status
      if (rsp.status === 200) {
        dispatch(
          signIn({
            authed: true,
            error: "",
          })
        );
        dispatch(
          signInUser({
            authed: true,
            data,
          })
        );
      } else {
        dispatch(
          signIn({
            authed: false,
            error: json.message ?? "Invalid username or password",
          })
        );
        dispatch(
          signInUser({
            authed: false,
            data,
          })
        );
      }
    } catch (err) {
      dispatch(
        signIn({
          authed: false,
          error: "login failed",
        })
      );
    }
  };
}

export function APISignOut() {
  return async (dispatch) => {
    ClearSession()
    try {
      await fetch("/api/logout", {
        method: "post",
      });
      dispatch(
        signOut({
          authed: false,
        })
      );
      dispatch(signOutUser({}));
    } catch (err) {
      dispatch(
        signOut({
          authed: false,
        })
      );
      dispatch(signOutUser({}));
    }
  };
}
