/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Logo from '../../../common/logo'
import MenuAccount from './menuAccount'
import MenuCart from './menuCart'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SetCategoryAction } from '../../../apis/products'
import { Link } from 'react-router-dom';
import { withRouter } from '../../../common/withRouter'
import FlagUS from "../../../assert/flag_us.svg"
import FlagAU from "../../../assert/flag_au.svg"
import FlagNZ from "../../../assert/flag_nz.svg"
import { APIGetTemplates } from '../../../apis/system'
import './navbar.css'

class NavBar extends Component {
    
    constructor(){
        super()
        this.state = {
            currCountryCode: localStorage.getItem('kCurrCountryCode') || 'us',
            promotions: [],
            showPromo: false,
            currentPromoIndex: 0
        }
    }

    componentDidMount() {
        this.checkAndRedirectCountry()
        // Close menu after clicking
        const navLinks = document.querySelectorAll('.nav-item custom-nav-item:not(.dropdown)')
        const menuToggle = document.getElementById('navbarNav')
        navLinks.forEach((l) => {
            l.addEventListener('click', () => {
                if (menuToggle.classList.contains('show')) {
                    menuToggle.classList.remove('show')
                }
            })
        })
        this.fetchPromotions()
    }

    componentWillUnmount() {
        if (this.promoInterval) {
            clearInterval(this.promoInterval);
        }
    }

    async fetchPromotions() {
        this.props.APIGetTemplates('PROMOTION_SLOGAN', (isSuccess, data, errorMessage) => {
            if (isSuccess) {
                let list = data.map((item) => {
                    return JSON.parse(item.content)
                })
                let activePromos = []
                list.forEach((item) => {
                    if (item.active === true) {
                        activePromos.push(item)
                    }
                })
                this.setState({ promotions: activePromos, showPromo: activePromos.length > 0 })
                if (activePromos.length > 1) {
                    this.promoInterval = setInterval(() => {
                        const { promotions, currentPromoIndex } = this.state;
                        if (promotions.length > 1) {
                            this.setState({
                                currentPromoIndex: (currentPromoIndex + 1) % promotions.length
                            });
                        }
                    }, 6000);
                }
            }
        })
    }    

    checkAndRedirectCountry() {
        let path = window.location.pathname
        const countryCode = path.includes('/au') ? 'au' : (path.includes('/nz') ? 'nz' : 'us')
        if (this.state.currCountryCode !== countryCode) {
            this.handleSelectCountry(this.state.currCountryCode)
        }
    }

    handleSelectCountry(countryCode) {
        localStorage.setItem('kCurrCountryCode', countryCode);
        this.setState({ currCountryCode: countryCode })

        let path = window.location.pathname
        if (countryCode === 'us') {
            path = path.replace(/\/(au|nz)/, '');
        } else {
            if (path.startsWith('/au') || path.startsWith('/nz')) {
                path = path.replace(/(au|nz)/, `${countryCode}`)
            } else {
                path = `/${countryCode}${path}`
            }
        }
        if (window.location.pathname !== path) {
            window.location.pathname = path
        }
    }

    render() {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const { categories } = this.props.prodState
        const reloadingCategory = categories?.filter(c => c.name.toLowerCase().includes("reloading"))[0]
        const cleaningCategory = categories?.filter(c => c.name.toLowerCase().includes("cleaning"))[0]
        const holeSawCategories = categories?.filter(c => c.name.toLowerCase().includes("saw"))
        const currPromo = this.state.promotions[this.state.currentPromoIndex]
        return (
            <div className='fixed-top'>
                { this.state.showPromo && (
                    <div className="center bg-warning text-dark" style={{ height: '16px' }}>
                        <a 
                        href={ currPromo.url }
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                        >
                            <p className='text-center'
                                style={{ fontSize: '11px', fontWeight: '500', margin: '0px', color: 'black' }}>
                                {currPromo.content}
                            </p>
                        </a>
                    </div>
                )}
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark"
                    style={{ minHeight: this.state.showPromo ? '42px' : '58px' }}>
                    <div className='container'>
                        <Logo isSmall={this.state.showPromo} />
                        <button
                            type="button"
                            className={`navbar-toggler custom-toggler ${this.state.showPromo ? 'small-toggler' : ''}`}
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className={`navbar-toggler-icon custom-toggler-icon ${this.state.showPromo ? 'small-toggler-icon' : ''}`}></span>
                        </button>
                        <div id="navbarNav" className="collapse navbar-collapse justify-content-end">
                            <ul className="navbar-nav">
                                <li className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''}`}>
                                    <a className="nav-link" href={`${region}/store`}>
                                        Store
                                    </a>
                                </li>
                                { reloadingCategory && (
                                    <li className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''}`}>
                                        <Link
                                            className='nav-link'
                                            to={`${region}/products?category=${reloadingCategory.id}`}
                                            onClick={()=> {
                                                this.props.SetCategoryAction(reloadingCategory.id)
                                                if (window.innerWidth < 992) {
                                                    document.querySelector('.navbar-toggler').click()
                                                }
                                        }}>
                                            Reloading
                                        </Link>
                                    </li>
                                    )
                                }
                                { cleaningCategory && (
                                    <li className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''}`}>
                                        <Link
                                            className='nav-link'
                                            to={`${region}/products?category=${cleaningCategory.id}`}
                                            onClick={()=> {
                                                this.props.SetCategoryAction(cleaningCategory.id)
                                                if (window.innerWidth < 992) {
                                                    document.querySelector('.navbar-toggler').click()
                                                }
                                        }}>
                                            Cleaning
                                        </Link>
                                    </li>
                                    )
                                }
                                <li className={`nav-item dropdown ${this.state.showPromo ? 'small-nav-item' : ''}`}>
                                    <a
                                        href="#menu"
                                        id="navbarDropdown"
                                        className='nav-link dropdown-toggle'
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        Hole Saw
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {
                                            (typeof holeSawCategories !== 'undefined' && holeSawCategories !== null) && holeSawCategories.map(category => {
                                                return (
                                                    <li key={category.id}
                                                        className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''} active`}>
                                                        <Link className='dropdown-item' to={`${region}/products?category=${category.id}`} onClick={()=> {
                                                            this.props.SetCategoryAction(category.id)
                                                            if (window.innerWidth < 992) {
                                                                document.querySelector('.navbar-toggler').click()
                                                            }
                                                        }}>
                                                            {category.name}
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                                {/* <li className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''}`}>
                                    <Link className='nav-link' to={`${region}/oem`}>
                                        OEM
                                    </Link>
                                </li>
                                <li className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''}`}>
                                    <Link className='nav-link' to={`${region}/dealer`}>
                                        Dealer
                                    </Link>
                                </li> */}
                                { (region !== "/au" && region !== "/nz") && (
                                    <li className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''}`}>
                                        <MenuAccount />
                                    </li>
                                )}
                                { (region !== "/au" && region !== "/nz") && (
                                    <li className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''}`}>
                                        <MenuCart />
                                    </li>
                                )}
                                <li className={`nav-item ${this.state.showPromo ? 'small-nav-item' : ''} dropdown`}>
                                    <a href="#country"
                                        className="nav-link dropdown-toggle"
                                        id="navCountryDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        { this.state.currCountryCode === 'us' && <img src={FlagUS} alt="US Flag" style={{ width: '20px', margin: '4px', borderColor: 'white', outline: '1px solid #CCC' }} />}
                                        { this.state.currCountryCode === 'au' && <img src={FlagAU} alt="AU Flag" style={{ width: '20px', margin: '4px', borderColor: 'white', outline: '1px solid #CCC' }} />}
                                        { this.state.currCountryCode === 'nz' && <img src={FlagNZ} alt="NZ Flag" style={{ width: '20px', margin: '4px', borderColor: 'white', outline: '1px solid #CCC' }} />}
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navCountryDropdown">
                                        {this.state.currCountryCode !== 'us' && (
                                            <li onClick={() => this.handleSelectCountry('us')}>
                                                <a className="dropdown-item" style={{
                                                    cursor: 'pointer'
                                                }}>
                                                    <img src={FlagUS} alt="US Flag" style={{ width: '20px', marginRight: '8px' }} />USA
                                                </a>
                                            </li>
                                        )}
                                        {this.state.currCountryCode !== 'au' && (
                                            <li onClick={() => this.handleSelectCountry('au')}>
                                                <a className="dropdown-item" style={{
                                                    cursor: 'pointer'
                                                }}>
                                                    <img src={FlagAU} alt="Australia Flag" style={{ width: '20px', marginRight: '8px' }} />Australia
                                                </a>
                                            </li>
                                        )}
                                        {this.state.currCountryCode !== 'nz' && (
                                            <li onClick={() => this.handleSelectCountry('nz')}>
                                                <a className="dropdown-item" style={{
                                                    cursor: 'pointer'
                                                }}>
                                                    <img src={FlagNZ} alt="New Zealand Flag" style={{ width: '20px', marginRight: '8px' }} />New Zealand
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    prodState: state.prodState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        SetCategoryAction,
        APIGetTemplates
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))