import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import PlusLogo from "../../../assert/plus.svg"
import './cartAddressesModel.css'
import CartEditAddressModal from './cartEditAddressModal'
import { addGuestAddress, getGuestAddresses, removeGuestAddress, updateGuestAddress } from '../../../common/guestCart'
import { useSelector } from 'react-redux'
import { APICreateOrUpdateAddress } from '../../../apis/user'
import { v4 as uuidv4 } from 'uuid'  // Add this line

const CartAddressesModal = ({ 
    showDialog, 
    onHide, 
    dialogTitle,
    userInfo,
    onAddressSelect
}) => {
    const addresses = useSelector(state => { return state.userState.addresses || [] })
    const createOrUpdateAddressStatus = useSelector(state => { return state.userState.createOrUpdateAddressStatus })
    const [guestAddresses, setGuestAddresses] = useState(getGuestAddresses())
    const [editAddress, setEditAddress] = useState(null)
    const [isShowingEditAddressModal, setIsShowingEditAddressModal] = useState(false)

    useEffect(() => {
        if (editAddress) {
            setIsShowingEditAddressModal(true)
        } else {
            setIsShowingEditAddressModal(false)
        }
    }, [editAddress])
    
    const closeEditAddressModal = () => {
        setEditAddress(null)
    }

    const isUserAuthed = () => {
        return userInfo !== undefined && userInfo !== null && Object.keys(userInfo).length > 0
    }

    const renderAddNewForModal = () => {
        return <div key='newAddress'
                    className='addNewAddressCard'
                    onClick={() => {
                        if (isUserAuthed()) {
                            window.open('/user/addresses/edit', '_self')
                        } else {
                            setEditAddress(null)
                            setEditAddress({ country: "US" })
                        }
                    }}>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center' }}>
                <img src={PlusLogo} style={{ width: '32px', height: '32px', alignSelf: 'center', userSelect: 'none' }} alt="Plus" />
                <span style={{ userSelect: 'none', width: '100%', fontSize: '13px' }}>Add Address</span>
            </div>
        </div>
    }

    const renderSingleAddress = (address, isDashed, showActions) => {
        let key = address.id
        if (typeof key === 'undefined' || key === null) {
            key = uuidv4()
        }
        return <div key={key}
                    className={`chooseAddressCard + ${isDashed ? ' dashedBorder' : ''}`}
                    onClick={(e) => {
                        if (['a', 'button', 'input'].includes(e.target.nodeName.toLowerCase())) {
                            return
                        } else {
                            onAddressSelect(address)
                        }
                    }}>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <div className='chooseAddressCardText'>
                    <span>{(address.name || address.fullName || "").toUpperCase()}</span>
                </div>
                <div className='chooseAddressCardText'>
                    <span>{(address.phone || "").toUpperCase()}</span>
                </div>
                <div className='chooseAddressCardText'>
                    <span>{(address.email || "").toUpperCase()}</span>
                </div>
                <div className='chooseAddressCardText'>
                    <span>{(address.address || "").toUpperCase()}</span>
                </div>
                <div className='chooseAddressCardText'>
                    <span>{(address.city || "").toUpperCase()}</span>
                </div>
                <div className='chooseAddressCardText'>
                    <span>{(address.state || "").toUpperCase()}</span>
                    <span> </span>
                    <span>{(address.postal || "").toUpperCase()}</span>
                </div>
                <div className='chooseAddressCardText'>
                    <span>{(address.country || "").toUpperCase()}</span>
                </div>
            </div>
            {
                showActions && (
                    <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                        <Button
                            className='CommonButton Transparent shadow-none'
                            style={{
                                fontSize: '11px',
                                textDecoration: 'underline',
                                marginRight: '4px'
                            }}
                            variant="warning"
                            onClick={ () => {
                                setEditAddress(null)
                                setEditAddress(address)
                            } }>
                            Edit
                        </Button>
                        <span style={{ marginLeft: '8px', marginRight: '8px', fontSize: '13px' }}>|</span>
                        <Button
                            className='CommonButton Transparent shadow-none'
                            style={{
                                fontSize: '11px',
                                textDecoration: 'underline',
                                marginRight: '4px'
                            }}
                            variant="warning"
                            onClick={ () => {
                                removeGuestAddress(address.id)
                                setGuestAddresses(getGuestAddresses())
                            } }>
                            Delete
                        </Button>
                    </div>
                )
            }
        </div>
    }

    const renderAddresses = () => {
        const isAuthed = isUserAuthed()
        const showActions = !isAuthed
        const displayAddresses = isAuthed ? [userInfo, ...addresses] : guestAddresses
        return (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {isAuthed && renderSingleAddress(userInfo, true)}
                {displayAddresses.map((item, index) => renderSingleAddress(item, false, showActions, index))}
                {renderAddNewForModal()}
            </div>
        );
    }

    return (
        <>
            <Modal show={showDialog} fullscreen='sm-down' onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '20px', fontWeight: 'bolder' }}>
                        {dialogTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { renderAddresses() }
                </Modal.Body>
            </Modal>

            <CartEditAddressModal
                showDialog={isShowingEditAddressModal}
                address={editAddress}
                onHide={closeEditAddressModal}
                onSave={ async (editAddress) => {
                    if (isUserAuthed()) {
                        await APICreateOrUpdateAddress(editAddress)
                        if (createOrUpdateAddressStatus.status === "LOADED") {
                            closeEditAddressModal()
                        }
                    } else {
                        if (typeof editAddress.id === 'undefined' || editAddress.id === null) {
                            addGuestAddress(editAddress)
                        } else {
                            updateGuestAddress(editAddress)
                        }
                        setGuestAddresses(getGuestAddresses())
                        closeEditAddressModal()
                    }
                }}
            />
        </>
    )
}

export default CartAddressesModal
