import React, { Component } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import "./signin.css"

class ForgotPassword extends Component {
    constructor() {
        super()
        this.state = {
            email:"",
            errMsg:"",
            status:"INIT"
        }
    }

    onValueChange = e => {
        this.setState({
            [e.target.name]:e.target.value,
            errMsg:""
        })
    }

    requestForgot = () => {
        //var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var pattern = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    
        let bEmailOK = pattern.test(this.state.email)
        if (!bEmailOK) {
            this.setState({
                errMsg:"Invalid email"
            })
            return 
        }
        this.setState({
            status:"REQUESTING"
        })
        fetch('/api/anonymous/reset', {
            method:"POST",
            body:JSON.stringify({
                email:this.state.email
            })
        })
        .then(res => {
            // eslint-disable-next-line eqeqeq
            if (res.status == 200) {
                this.setState({
                    status:"SUCCEED",
                    errMsg:""
                })
            } else {
                this.setState({
                    status:"INIT",
                    errMsg:"User was not found"
                })
            }
        })
        .catch(err => {
            this.setState({
                status:"INIT",
                errMsg:"Request error."
            })
        })
    }

    render() {

        let content = null
        let iptDisable = false  
        let spinner = null
        // eslint-disable-next-line eqeqeq
        if(this.state.status == "REQUESTING") {
            iptDisable = true
            spinner = <Spinner className='forgotSpinner' animation='border' />
        } 
        
        // eslint-disable-next-line eqeqeq
        if (this.state.status == "SUCCEED") {
            content = <div className='forgotForm'>
                <p>Reset password succeed.</p>
                <p>Please check your email. </p>
            </div>
        } else {
            content = <div className='forgotForm'>
                    <p>Enter the Email address of your McJ Tools Account.</p>
                    <input disabled={iptDisable} className='forgotIpt' name='email' placeholder='Email' onChange={this.onValueChange} value={this.state.email}/>
                    <p className='errMsg'>{this.state.errMsg}</p>
                    {spinner}
                    <Button variant='warning' disabled={iptDisable} onClick={this.requestForgot}>Reset Password</Button>
                </div>
        }

        return (
        <div className='root'>
            <div className='top'>
                <div className='signinBox'>
                    <h3>Reset Password</h3>
                    <hr/>
                    {content}
                </div>
            </div>
        </div>
        )
    }
}

export default ForgotPassword