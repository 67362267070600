import { HandleResponseJson, HandleResponseError  } from './api'
import { PayOrderAction } from './cart'
import MCJ from "../common/def"
import { CacheAndClearSession, clearSessionAndCachedSession, restoreCachedSession } from '../common/guestCart'

/*
[
  {
    "id": 0,
    "accountId": 0,
    "gateway": "string",
    "status": "string",
    "shippingType": "string",
    "reference": "string",
    "note": "string",
    "paymentMethods": [
      "string"
    ],
    "messageCount": 0,
    "createdAt": "string",
    "updatedAt": "string",
    "summary": {
      "price": "string",
      "fee": "string",
      "discount": "string",
      "subtotal": "string",
      "tax": "string",
      "taxRate": "string",
      "total": "string",
      "paid": "string"
    },
    "billing": {
      "name": "string",
      "phone": "string",
      "email": "string",
      "address": "string",
      "city": "string",
      "postal": "string",
      "state": "string",
      "country": "string"
    },
    "shipping": {
      "name": "string",
      "phone": "string",
      "email": "string",
      "address": "string",
      "city": "string",
      "postal": "string",
      "state": "string",
      "country": "string"
    },
    "stripe,omitempty": {
      "id": "string",
      "secret": "string",
      "status": "string",
      "note": "string"
    },
    "invoice,omitempty": {
      "id": "string",
      "url": "string",
      "pdf": "string",
      "status": "string",
      "note": "string"
    },
    "amazonPay,omitempty": {
      "sessionId,omitempty": "string",
      "chargeId,omitempty": "string",
      "status,omitempty": "string",
      "note,omitempty": "string",
      "payloadJSON,omitempty": "string",
      "signature,omitempty": "string",
      "merchantId,omitempty": "string",
      "publicKeyId,omitempty": "string"
    },
    "fulfill": {
      "status": "string",
      "note": "string"
    },
    "items": [
      {
        "id": 0,
        "orderHistoryId": 0,
        "productId": 0,
        "price": "string",
        "discounted": true,
        "quantity": 0,
        "msku": "string",
        "omsku": "string",
        "fnsku": "string",
        "fulfilled": 0,
        "manufactureId": 0,
        "warehouseId": 0,
        "createdAt": "string",
        "updatedAt": "string"
      }
    ],
    "fees,omitempty": [
      {
        "id": 0,
        "orderHistoryId": 0,
        "name": "string",
        "currencyCode": "string",
        "fee": "string",
        "createdAt": "string"
      }
    ],
    "discounts,omitempty": [
      {
        "id": 0,
        "orderHistoryId": 0,
        "name": "string",
        "promotionId": 0,
        "amount": "string",
        "createdAt": "string"
      }
    ]
  }
]
*/
export function APIGuestGetOrder(email, reference, callback) {
    return async dispatch => {
        try {
            const url = `/api/anonymous/order?email=${email}&reference=${reference}`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const errorMessage = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, errorMessage)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
{
  "note": "string",
  "code": "string",
  "gateway": "string",
  "TaxRate": 0,
  "billing": {
    "name": "string",
    "phone": "string",
    "email": "string",
    "address": "string",
    "city": "string",
    "postal": "string",
    "state": "string",
    "country": "string"
  },
  "shipping": {
    "name": "string",
    "phone": "string",
    "email": "string",
    "address": "string",
    "city": "string",
    "postal": "string",
    "state": "string",
    "country": "string"
  },
  "paymentMethods": [
    "string"
  ],
  "feeDetail": [
    {
      "name": "string",
      "currencyCode": "string",
      "fee": 0
    }
  ],
  "cart": [
    {
      "productId": 0,
      "quantity": 0
    }
  ]
}
*/
export function APIGuestCreateOrder(order, callback) {
    return async dispatch => {
        try {
            const url = `/api/anonymous/order`
            let rsp = await fetch(url, {
                method:'POST',
                body: JSON.stringify(order)
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
            if (isSuccess) {
                CacheAndClearSession()
                dispatch(PayOrderAction(MCJ.PAYMENT_INIT))
            }
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
{
  "id": 0,
  "accountId": 0,
  "gateway": "string",
  "status": "string",
  "shippingType": "string",
  "reference": "string",
  "note": "string",
  "paymentMethods": [
    "string"
  ],
  "messageCount": 0,
  "createdAt": "string",
  "updatedAt": "string",
  "summary": {
    "price": "string",
    "fee": "string",
    "discount": "string",
    "subtotal": "string",
    "tax": "string",
    "taxRate": "string",
    "total": "string",
    "paid": "string"
  },
  "billing": {
    "name": "string",
    "phone": "string",
    "email": "string",
    "address": "string",
    "city": "string",
    "postal": "string",
    "state": "string",
    "country": "string"
  },
  "shipping": {
    "name": "string",
    "phone": "string",
    "email": "string",
    "address": "string",
    "city": "string",
    "postal": "string",
    "state": "string",
    "country": "string"
  },
  "stripe,omitempty": {
    "id": "string",
    "secret": "string",
    "status": "string",
    "note": "string"
  },
  "invoice,omitempty": {
    "id": "string",
    "url": "string",
    "pdf": "string",
    "status": "string",
    "note": "string"
  },
  "amazonPay,omitempty": {
    "sessionId,omitempty": "string",
    "chargeId,omitempty": "string",
    "status,omitempty": "string",
    "note,omitempty": "string",
    "payloadJSON,omitempty": "string",
    "signature,omitempty": "string",
    "merchantId,omitempty": "string",
    "publicKeyId,omitempty": "string"
  },
  "fulfill": {
    "status": "string",
    "note": "string"
  },
  "items": [
    {
      "id": 0,
      "orderHistoryId": 0,
      "productId": 0,
      "price": "string",
      "discounted": true,
      "quantity": 0,
      "msku": "string",
      "omsku": "string",
      "fnsku": "string",
      "fulfilled": 0,
      "manufactureId": 0,
      "warehouseId": 0,
      "createdAt": "string",
      "updatedAt": "string"
    }
  ],
  "fees,omitempty": [
    {
      "id": 0,
      "orderHistoryId": 0,
      "name": "string",
      "currencyCode": "string",
      "fee": "string",
      "createdAt": "string"
    }
  ],
  "discounts,omitempty": [
    {
      "id": 0,
      "orderHistoryId": 0,
      "name": "string",
      "promotionId": 0,
      "amount": "string",
      "createdAt": "string"
    }
  ]
}
*/
export function APIGuestCompleteOrder(succeed, reference, callback) {
    return async dispatch => {
        try {
            restoreCachedSession()
            let url = `/api/anonymous/order?succeed=${succeed}&reference=${reference}`
            let rsp = await fetch(url, {
              method:'PUT'
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
            if (isSuccess) {
                clearSessionAndCachedSession()
            }
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}